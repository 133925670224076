import React from "react";
import "./ReleaseNotes.css";
import {
  AppContainer,
  AppMainContent,
  Dialog,
  Icon,
  Table,
  ThemeContext,
} from "@abb/abb-common-ux-react";
import { envSettings } from "../../../EnviornmentSettings/Settings";
import { useSelector } from "react-redux";
import { IState } from "../../../Reducer";

interface IReleaseNotesProps {
  showReleaseNotes: boolean;
  onCloseReleaseNotes: () => void;
}
const ReleaseNotes = (props: IReleaseNotesProps) => {
  const commonState = useSelector((state: IState) => state.commonState);
  const handleEscape = (e: any) => {
    if (e.keyCode === 27) {
      props.onCloseReleaseNotes();
    }
  };
  React.useEffect(() => {
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, []);
  const currentTime = new Date();
  let formattedDate = currentTime.toLocaleString();
  formattedDate = formattedDate.replace(/\//g, "-");
  formattedDate = formattedDate.replace(/,/g, "");
  return (
    // <Dialog
    //   isOpen={props.showReleaseNotes}
    //   showCloseButton={true}
    //   onClose={() => props.onCloseReleaseNotes()}
    //   className={`release-note-view`}
    //   title="Release Notes"
    // >
    <div
      className={`release-notes-wrap ${
        commonState.theme === "light"
          ? "light-view-container"
          : "dark-view-container"
      }`}
    >
      <div className="notes-heading">
        <span>Release Notes </span>
        <div>
          <Icon
            name="abb/close"
            className="notes-close-icon"
            onClick={() => props.onCloseReleaseNotes()}
          />
        </div>
      </div>
      <AppContainer theme={commonState.theme}>
        <AppMainContent>
          <div className="release-note-container">
            <div className="release-note-content">
              <p />
              <div className="release-notes-header-wrap">
                <div className="release-note-version">{`VERSION  2.0.0.0`}</div>
                <div className="release-note-time-stamp">{`${formattedDate}`}</div>
              </div>
              <hr />
              <div className="release-notes-app-details">
                Modbus Configuration Tool provides ability to upload Modbus
                configuration excel data sheet for configuring connectivity
                between Modbus TCP devices and the Edge.
              </div>

              <p>
                <p className="release-note-contentheaders">Key Features</p>
                <ul>
                  <li>Log formatting improvements and bug fixes.</li>
                </ul>
              </p>
              <p>
                <i className="info-text">
                  Note: This version is available only for Disconnected
                  Edgenius.
                </i>
              </p>
              <p>
                <p className="release-note-contentheaders">Known Issues</p>
                <Table>
                  <tr>
                    <th>
                      <div className="release-notes-table-each-header">
                        Issue
                      </div>
                    </th>
                    <th>
                      <div className="release-notes-table-each-header">
                        Workarounds, Clarifications and helpful hints
                      </div>
                    </th>
                    <th>
                      <div className="release-notes-table-each-header">
                        Issue Id
                      </div>
                    </th>
                  </tr>
                  <tbody>
                    <tr>
                      <td>
                        Sampling interval for Modbus variables does not work if
                        configured from OPC UA Config Tool
                      </td>
                      <td>
                        Configure the sampling interval of the Modbus variables
                        using the Modbus Configuration excel template. Ensure
                        ScanMode is set as TagSpecificScanRate in the device tab
                        of Modbus excel template
                      </td>
                      <td>EDGUMODCONN-OL-1200-54658</td>
                    </tr>
                  </tbody>
                </Table>
              </p>
            </div>
          </div>
        </AppMainContent>
      </AppContainer>
    </div>
    //</Dialog>
  );
};

export default ReleaseNotes;
