import { Icon, AppMainContent } from "@abb/abb-common-ux-react";
import React, { useEffect } from "react";
import { helpPageTreeData } from "../../../Utils/Constants";
import "./Documentation.css";
import Tree, { ITreeDataObj } from "../Tree/Tree";
import { RouteComponentProps, withRouter } from "react-router";
import * as commonActions from "../../Action/ActionCommon";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../Reducer";
import CommonLoader from "../CommonLoader/CommonLoader";

const refs: any = {};
helpPageTreeData.map((item) => {
  refs[item.id] = React.createRef();
});

const Documentation = (props: RouteComponentProps) => {
  const [tocData, handleExpandCollapse] = React.useState([...helpPageTreeData]);
  const [selectedNodeId, setSelectedNodeId] = React.useState(
    helpPageTreeData[0].id
  );
  const [nodeClickCount, setNodeClickCount] = React.useState(0);
  const [showLeftPane, toggleLeftPane] = React.useState(true);
  const commonState = useSelector((state: IState) => state.commonState);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.getPreference());
  }, []);
  React.useEffect(() => {
    const elmnt = document.getElementById(selectedNodeId);
    if (elmnt && elmnt !== null) {
      elmnt?.scrollIntoView();
    }
  }, [nodeClickCount]);
  const clickedNode = (event: any, clickedObj: ITreeDataObj) => {
    event.stopPropagation();
    setSelectedNodeId(clickedObj.id);
    setNodeClickCount(nodeClickCount + 1);
  };
  const handleExpandCollapseNode = (event: any, clickedObj: ITreeDataObj) => {
    event.stopPropagation();
    if (clickedObj.isExpandable) {
      const updatedData = [...tocData];
      updatedData.map((item) => {
        if (item.id === clickedObj.id) {
          item.isExpanded = !item.isExpanded;
        }
      });
      if (clickedObj.isExpanded) {
        updatedData.map((item) => {
          if (
            item["id"].indexOf(clickedObj.id) >= 0 &&
            item.level > clickedObj.level
          ) {
            item.isExpanded = false;
            item.isVisible = false;
          }
        });
      } else {
        updatedData.map((item) => {
          if (
            item.id.indexOf(clickedObj.id) >= 0 &&
            item.level === clickedObj.level + 1
          ) {
            item.isExpanded = false;
            item.isVisible = true;
          }
        });
      }
      handleExpandCollapse([...updatedData]);
    }
  };

  const handleDocClick = (clickedId: string) => {
    let modifiedId = clickedId;
    const updatedData = [...tocData];
    updatedData.reverse();
    updatedData.some((item) => {
      if (clickedId.indexOf(item.id) >= 0 && item.isVisible) {
        modifiedId = item.id;
        return true;
      }
      return false;
    });
    setSelectedNodeId(modifiedId);
  };

  const tooglePane = () => {
    toggleLeftPane(!showLeftPane);
  };
  return (
    <React.Fragment>
      {commonState.isLoading ? <CommonLoader /> : <div />}
      {commonState.isLoading ? <div className="theme-loader-mask" /> : <div />}
      <AppMainContent>
        <div className="docs">
          <div>
            <div className="menu" onClick={tooglePane}>
              <Icon name="abb/menu" sizeClass="large" />
            </div>
            {showLeftPane ? (
              <Tree
                data={tocData}
                selectedNodeId={selectedNodeId}
                handleExpandCollapse={handleExpandCollapseNode}
                handleNodeSelection={clickedNode}
              />
            ) : (
              <React.Fragment />
            )}
          </div>
          <DisplayNewDoc onDocClick={handleDocClick} />
        </div>
      </AppMainContent>
    </React.Fragment>
  );
};

const DisplayNewDoc = (props: any) => {
  return (
    <div className="doc-view-container" id="scrolling-div">
      <div>
        <div
          id="%1.introduction%"
          className="level-1-padding"
          ref={refs["%1.introduction%"]}
          onMouseOver={() => props.onDocClick("%1.introduction%")}
        >
          <p className="headers-document first-header">1. Introduction</p>
          <p>
            The Modbus Configuration Utility provides the capability to connect
            to Modbus devices via the Edgenius Edge node and expose the Modbus
            TCP variables as OPC UA tags.
          </p>
          <p>
            The Modbus TCP Configuration Utility is divided into two parts:
            Modbus TCP connect and Modbus TCP configuration utility tool. Once
            the Modbus TCP Connect is configured, the Modbus data becomes
            available as an internal OPC UA Server on edge. The Edgenius OPC UA
            Connect module can then be configured to subscribe for this UA
            server data to make it available to the edge hot data stream.
          </p>
          <p>
            The typical workflow to configure Modbus TCP is divided into the
            following main steps
          </p>
          <ul>
            <li>
              Prepare the Modbus Configuration Excel sheet with Modbus TCP
              channels, devices, variables etc. to match the actual Modbus
              device configuration. This Excel sheet is available as a template
              with the product.
            </li>
            <li>
              Upload the Modbus Configuration Excel data sheet using the Modbus
              TCP Configuration Utility to deploy the configuration to the
              Modbus TCP Connect application in the configured Edgenius Edge
              Node. The connected Modbus device data becomes available as the
              address space of an internal edge OPC UA Server.
            </li>
            <li>
              Use OPC UA Connect and its configuration tool to configure and
              subscribe for the Modbus data available from the internal (Modbus)
              OPC UA server address space.
            </li>
          </ul>
        </div>

        <div
          id="%1.introduction%%1.1rbac%"
          className="level-2-padding"
          ref={refs["%1.introduction%%1.1rbac%"]}
          onMouseOver={() => props.onDocClick("%1.introduction%%1.1rbac%")}
        >
          <p className="headers-document first-header">1.1 RBAC</p>
          <p>
            Role based access control is used to authorize functionality. For
            the Edge Principal Manager there are two levels, User and Software
            Developer. For access to Modbus Configuration Utility,
            “ApplicationEngineer” grant is required.
          </p>
        </div>
        <div
          id="%2.installationandconfig%"
          className="level-2-padding"
          ref={refs["%2.installationandconfig%"]}
          onMouseOver={() => props.onDocClick("%2.installationandconfig%")}
        >
          <p className="headers-document first-header">
            2. Installation & Configuration
          </p>
          <p>
            The Modbus TCP Connect application is a Modbus client running on
            Edgenius. It provides southbound connectivity to Modbus TCP devices
            or system.
          </p>
        </div>

        <div
          id="%2.installationandconfig%%2.1.installation%"
          className="level-2-padding"
          ref={refs["%2.installationandconfig%%2.1.installation%"]}
          onMouseOver={() =>
            props.onDocClick("%2.installationandconfig%%2.1.installation%")
          }
        >
          <p className="headers-document first-header">2.1. Installation</p>
          <p>
            Modbus TCP Connect and Modbus TCP Config are available as Edgenius
            applications which can be installed on an Edge device. Both
            applications must be installed on a disconnected edge. For
            information about installation, update or uninstall of Edgenius
            application please refer the Disconnected Edgenius Node Installation
            Guide (7PAA005993*).
          </p>
          <p>
            Both the OPC UA config and OPC UA connect applications must be
            installed to be able to read Modbus data and then visualize the same
            in any Edgenius application such as: Dashboard, SCE.
          </p>
          <p>
            Users should have the 'ApplictionEngineer' grant which can be
            configured from the Edge Principal Manager to access Modbus
            Configuration Utility.
          </p>
        </div>

        <div
          id="%2.installationandconfig%%2.2.configuration%"
          className="level-2-padding"
          ref={refs["%2.installationandconfig%%2.2.configuration%"]}
          onMouseOver={() =>
            props.onDocClick("%2.installationandconfig%%2.2.configuration%")
          }
        >
          <p className="headers-document first-header">2.2 Configuration</p>
          <p>
            OPC UA Connect module is dependency for MOdbus Connect module.
            Please refer OPC UA Config Tool user guide.
          </p>
          <p>
            No other configuration is required for Modbus TCP Connect in
            Edgenius.
          </p>
        </div>
        <div
          id="%3.modbusconfigutility%"
          className="level-1-padding"
          ref={refs["%3.modbusconfigutility%"]}
          onMouseOver={() => props.onDocClick("%3.modbusconfigutility%")}
        >
          <p className="headers-document first-header">
            3. Modbus Configuration Utility
          </p>
        </div>
        <div
          id="%3.modbusconfigutility%%3.1.modbusconfigfileupload%"
          className="level-2-padding"
          ref={refs["%3.modbusconfigutility%%3.1.modbusconfigfileupload%"]}
          onMouseOver={() =>
            props.onDocClick(
              "%3.modbusconfigutility%%3.1.modbusconfigfileupload%"
            )
          }
        >
          <p className="headers-document first-header">
            3.1. Modbus Configuration File Upload
          </p>
          <p>Modbus Configuration Tool is a web application.</p>
          <p>The Modbus Configuration Utility allows users to:</p>
          <p>
            <ul>
              <li>
                Configure Modbus TCP connectivity and upload a Modbus
                configuration Excel file.
              </li>
            </ul>
          </p>
          <p>
            <img
              src="images/userDocImages/fileupload1.png"
              className="image-margin"
            />
            <div>Figure 1: Connected Modbus TCP Edge Devices</div>
          </p>
          <p>
            Clicking on the{" "}
            <span>
              <img
                src="images/userDocImages/fileupload2.png"
                className="line-image-margin"
              />
            </span>{" "}
            icon against a Modbus TCP Edge node will open a popup menu.{" "}
          </p>
          <p>
            <img
              src="images/userDocImages/fileupload3.png"
              className="image-margin"
            />
          </p>
          <p>Figure 3-1: Options Menu</p>
          <p>
            Clicking on{" "}
            <span>
              <img
                src="images/userDocImages/fileupload4.png"
                className="line-image-margin"
              />
            </span>{" "}
            will open a File upload dialog where we can upload the Modbus
            Configuration file.
          </p>
          <p>
            <img
              src="images/userDocImages/fileupload5.png"
              className="image-margin"
            />
          </p>
          <p>Figure 3-2: File Upload</p>
          <p>
            Browse and select the Modbus configuration excel file and click on
            Submit to upload the configuration.
          </p>
          <p>
            A confirmation message will be displayed once the Modbus
            Configuration file is successfully uploaded.
          </p>
          <p>
            <img
              src="images/userDocImages/fileupload6.png"
              className="image-margin"
            />
          </p>
          <p>Figure 3-3: Confirmation Message</p>
        </div>
        <div
          id="%3.modbusconfigutility%%3.2opcuamodbbusconfig%"
          className="level-2-padding"
          ref={refs["%3.modbusconfigutility%%3.2opcuamodbbusconfig%"]}
          onMouseOver={() =>
            props.onDocClick("%3.modbusconfigutility%%3.2opcuamodbbusconfig%")
          }
        >
          <p className="headers-document first-header">
            3.2. OPC UA Configuration Utility – Modbus Configuration
          </p>
          <p>
            Based on the uploaded Modbus configuration, the Modbus TCP variables
            will be exposed as OPC UA Tags which can be configured and
            subscribed from OPC UA Config Tool.
          </p>
          <p>
            <img
              src="images/userDocImages/opcservers.png"
              className="image-margin"
            />
          </p>
          <p>
            To add a new connection to the Modbus TCP Server running in the Edge
            Node, click on the{" "}
            <span>
              <img
                src="images/userDocImages/modbusconfig1.png"
                className="line-image-margin"
              />
            </span>{" "}
            button in ‘OPC Servers’ page.{" "}
          </p>
          <p>
            The following fields need to be filled in for establishing
            connection to the Modbus TCP OPC UA Server.
            <p>
              <ul>
                <li>
                  Hosting Edge: Edge from which connection to a southbound OPC
                  UA Server will be established.
                </li>
                <li>
                  OPC UA Server Name: Name provided by the user to identify OPC
                  UA Server.
                </li>
                <li>
                  OPC UA Endpoint: URL of the OPC UA Server -
                  opc.tcp://modbusconnect:48030
                </li>
                <li>
                  Server Description: Server description provided by the user.
                </li>
                <li>
                  Profile - Server profile to be either HA or DA selected by
                  user.
                </li>
                <p>
                  <img
                    src="images/userDocImages/modbusaddserver.png"
                    className="image-margin"
                  />
                </p>
                <p>Figure 3-4: OPC Configuration Utility</p>
                <li>
                  Configure secure connection: Enables Upload File button for
                  uploading OPC UA Server certificate.
                </li>
                <li>
                  User Authentication: Check this option to enable secure
                  communication between the OPC UA Server and the OPC UA
                  Configuration Utility client. Enter the Username and Password
                  in order to authenticate the user identity, the OPC UA server
                  uses the user identity token it received to accept or reject
                  the communication.
                </li>
              </ul>
            </p>
          </p>
          <p>
            <ol>
              <li>
                Once the details are provided click Connect to server button.
              </li>
              <li>
                Select Automatic configuration and click Submit to initiate
                upload of Node set configuration.
              </li>
              <p>
                <img
                  src="images/userDocImages/modbusUploadnodesetfile.png"
                  className="image-margin"
                />
              </p>
              <p>Figure 3-5: Nodeset Configuration Upload</p>
              <li>Upload the Nodeset file and click Submit.</li>
              <p>
                <img
                  src="images/userDocImages/modbusconnectserversuccess.png"
                  className="image-margin"
                />
              </p>
              <p>Figure 3-6: Configuration of Modbus TCP OPC UA Server</p>
              <li>
                Once the Server configuration is saved, the Modbus TCP OPC UA
                Server will be added to the list of connected Servers on the
                Edge Device with the status set to Ready.
              </li>
              <li>
                Click on the configured Server from the list to browse the
                server objects.
              </li>
              <li>
                The Modbus TCP variables can now be browsed and subscribed from
                the OPC UA Configuration Utility
              </li>
              <p>
                <img
                  src="images/userDocImages/modbusbrowse.png"
                  className="image-margin"
                />
              </p>
              <p>Figure 3-7: List of connected Servers</p>
            </ol>
          </p>
          <p>
            For additional details please refer OPC UA Configuration Utility
            user guide.
          </p>
        </div>

        <div
          id="%4.exceltemplate%"
          className="level-1-padding"
          ref={refs["%4.exceltemplate%"]}
          onMouseOver={() => props.onDocClick("%4.exceltemplate%")}
        >
          <p className="headers-document first-header">
            4. Modbus Configuration Excel Template
          </p>
          <p>
            An Excel Template is used to specify the Modbus TCP configuration
            which is then uploaded from the Modbus Configuration Utility. Please
            contact ABB technical support or your local ABB representative to
            get the Modbus Configuration excel template.
          </p>
          <p>
            The Excel template is available only in ‘.xlsx’ format. Converting
            the excel template to ‘.xls’ format is not recommended as it will
            remove the data field validations.
          </p>
          <p>
            This section provided details on the Excel template fields that
            needs to be filled in for configuring Modbus TCP devices.
          </p>
          <p>
            <img
              src="images/userDocImages/exceltemplate1.png"
              className="image-margin"
            />
          </p>
          <p>Figure 4-1: Excel Template fields</p>
          <p>
            The Excel Template has 7 Tabs that needs to be updated before
            uploading. Some of the columns/fields have drop down lists
            configured that shows the list of accepted values. Please select the
            value from drop down instead of typing the text in those fields
            directly. These fields accept text in the format as defined in the
            drop down (e.g. the input text is in camelCase and typing in the
            value in lower case is not valid) and providing a different input
            will result in validation errors during configuration file upload.
          </p>
          <p>
            The below section describes each tab and its corresponding rows.
          </p>
        </div>
        <div
          id="%4.exceltemplate%%4.1.modbustcpchannel%"
          className="level-2-padding"
          ref={refs["%4.exceltemplate%%4.1.modbustcpchannel%"]}
          onMouseOver={() =>
            props.onDocClick("%4.exceltemplate%%4.1.modbustcpchannel%")
          }
        >
          <p className="headers-document first-header">4.1. ModbusTCPChannel</p>
          <p>
            Each protocol or driver used in a Modbus TCP server project is
            called as channel. A server project may consist of many channels
            with the same communications driver or with unique communications
            drivers. A channel acts as the basic building block of an OPC link
            to a Modbus TCP server.
          </p>
          <p>
            The tab lists the attributes column for configuring the Modbus TCP
            Channels. Each row defines the configuration for a Modbus TCP
            Channel.
          </p>
          <p>
            <img
              src="images/userDocImages/exceltemplate2.png"
              className="image-margin"
            />
          </p>
          <p>Figure 4-2: Modbus TCP Channel Tab</p>
          <p>
            <table>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "80%" }} />
              </colgroup>
              <tr>
                <th colSpan={2}>ModbusTCPChannel Tab </th>
              </tr>
              <tr>
                <th>Field</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>Id</td>
                <td>
                  This column specifies a unique 16-bit GUID string for the
                  channel.
                </td>
              </tr>
              <tr>
                <td>Name</td>
                <td>
                  This property specifies the name of the channel. A logical
                  user-defined name can be used.
                </td>
              </tr>
              <tr>
                <td>IP Address</td>
                <td>
                  This property specifies the IPV4 address of the terminal
                  server to which this device is attached.
                </td>
              </tr>
              <tr>
                <td>Port</td>
                <td>
                  This column signifies a specific port number that used when
                  connecting to a remote Modbus device. Please add the specified
                  port in the Firewall Exception list in the network where the
                  Modbus device is connected.
                </td>
              </tr>
              <tr>
                <td>SocketUsage (drop down)</td>
                <td>Default option is OneSocketPerChannel</td>
              </tr>
            </table>
          </p>
          <p>Table 4-1: Modbus TCP Channel Tab</p>
        </div>
        <div
          id="%4.exceltemplate%%4.2.device%"
          className="level-2-padding"
          ref={refs["%4.exceltemplate%%4.2.device%"]}
          onMouseOver={() => props.onDocClick("%4.exceltemplate%%4.2.device%")}
        >
          <p className="headers-document first-header">4.2. Device</p>
          <p>
            The Device Tab lists the attribute columns for Modbus devices. Each
            row represents a single target on a communication channel.
          </p>
          <p>
            <img
              src="images/userDocImages/exceltemplate3.png"
              className="image-margin"
            />
          </p>
          <p>Figure 4-3: The Device Tab</p>
          <p>
            <table>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "80%" }} />
              </colgroup>
              <tr>
                <th colSpan={2}>Device Tab </th>
              </tr>
              <tr>
                <th>Field</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>Associated Channel Id (drop down)</td>
                <td>
                  This column specifies the parent channel id from the
                  ModbusTCPChannel tab.
                </td>
              </tr>
              <tr>
                <td>Id</td>
                <td>
                  This column specifies a unique 16-bit GUID string for the
                  device
                </td>
              </tr>
              <tr>
                <td>Name</td>
                <td>
                  This column specifies the name of the device. A logical
                  user-defined name can be used.
                </td>
              </tr>
              <tr>
                <td>Address</td>
                <td>This column specifies the device address</td>
              </tr>
              <tr>
                <td>Scan Mode (drop down)</td>
                <td>
                  <p>
                    The Scan Mode specifies the subscribed client requested scan
                    rate for tags that require device communications. This is a
                    drop-down field which has two options:
                  </p>
                  <p>
                    <ul>
                      <li>
                        ClientSpecificScanRate: This mode uses the scan rate
                        requested by the client.
                      </li>
                      <li>
                        TagSpecificScanRate: This mode forces static tags to be
                        scanned at the rate specified in their static
                        configuration tag properties (ScanRate column in the
                        ‘Tag’ tab). Dynamic tags are scanned at the
                        client-specified scan rate.{" "}
                      </li>
                    </ul>
                  </p>
                  <p>
                    Default is ClientSpecificScanRate. For subscribing tags at a
                    specific rate defined in the template, select
                    TagSpecificScanRate.{" "}
                  </p>
                </td>
              </tr>
              <tr>
                <td>ByteOrder (drop down)</td>
                <td>
                  This column specifies the byte order (Little-endian or
                  Big-endian)
                </td>
              </tr>
              <tr>
                <td>Type (drop down)</td>
                <td>
                  Type on a device refers to the object type of the node in the
                  address space. It holds the Id of the actual type definition.
                  This should refer the types configured in the Types Tab which
                  can be selected from the drop down.
                </td>
              </tr>
            </table>
          </p>
          <p>Table 4-2: Device Tab</p>
        </div>

        <div
          id="%4.exceltemplate%%4.3.communicationtimeout%"
          className="level-2-padding"
          ref={refs["%4.exceltemplate%%4.3.communicationtimeout%"]}
          onMouseOver={() =>
            props.onDocClick("%4.exceltemplate%%4.3.communicationtimeout%")
          }
        >
          <p className="headers-document first-header">
            4.3. CommunicationTimeouts
          </p>
          <p>
            This tab specifies the device Timeout properties that allow the
            driver's response to error conditions. Each row specifies the
            timeout and retry configuration for a device.
          </p>
          <p>
            <img
              src="images/userDocImages/exceltemplate4.png"
              className="image-margin"
            />
          </p>
          <p>Figure 4-4: Communication Timeouts Tab</p>
          <p>
            <table>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "80%" }} />
              </colgroup>
              <tr>
                <th colSpan={2}>CommunicationTimeouts Tab </th>
              </tr>
              <tr>
                <th>Column Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>Channel Id (drop down)</td>
                <td>
                  This column specifies the parent channel id from the
                  ModbusTCPChannel tab.
                </td>
              </tr>
              <tr>
                <td>Device Id (drop down)</td>
                <td>
                  This column specifies the linked device id from the Device
                  tab.
                </td>
              </tr>
              <tr>
                <td>Connection Timeout</td>
                <td>
                  This column controls the amount of time required to establish
                  a socket connection to a remote device. The device's
                  connection time often takes longer than normal communications
                  requests to that same device.
                </td>
              </tr>
              <tr>
                <td>Request Timeout</td>
                <td>
                  This column specifies an interval used by all drivers to
                  determine how long the driver waits for a response from the
                  target device to complete.
                </td>
              </tr>
              <tr>
                <td>No of Retry Attempts</td>
                <td>
                  This column specifies how many times the driver issues a
                  communications request before considering the request to have
                  failed and the device to be in error.
                </td>
              </tr>
              <tr>
                <td>InterRequestDelay</td>
                <td>
                  This column specifies how long the driver waits before sending
                  the next request to the target device
                </td>
              </tr>
            </table>
          </p>
          <p>Table 4-3: Communication Timeouts Tab</p>
        </div>

        <div
          id="%4.exceltemplate%%4.4.autodemotion%"
          className="level-2-padding"
          ref={refs["%4.exceltemplate%%4.4.autodemotion%"]}
          onMouseOver={() =>
            props.onDocClick("%4.exceltemplate%%4.4.autodemotion%")
          }
        >
          <p className="headers-document first-header">4.4. AutoDemotion</p>
          <p>
            The Auto-Demotion tab allow a driver to temporarily place a device
            off-scan if a device is not responding. By placing a non-responsive
            device offline, the driver can continue to optimize its
            communications with other devices on the same channel by stopping
            communications with the non-responsive device for a specific period.
            After the specified time period has been reached, the driver
            re-attempts to communicate with the non-responsive device. If the
            device is responsive, the device is placed on-scan; otherwise, it
            restarts its off-scan time period.{" "}
          </p>
          <p>
            <img
              src="images/userDocImages/exceltemplate5.png"
              className="image-margin"
            />
          </p>
          <p>Figure 4-5: Auto Demotion Tab</p>
          <p>
            <table>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "80%" }} />
              </colgroup>
              <tr>
                <th colSpan={2}>AutoDemotion Tab </th>
              </tr>
              <tr>
                <th>Column Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>Channel Id (drop down)</td>
                <td>
                  This column specifies the parent channel id from the
                  ModbusTCPChannel tab.
                </td>
              </tr>
              <tr>
                <td>Device Id (drop down)</td>
                <td>
                  This column specifies the linked device id from the Device
                  tab.
                </td>
              </tr>
              <tr>
                <td>Enabled (drop down)</td>
                <td>
                  This column specifies whether the Auto demotion feature is
                  enabled or disabled for the device based on a flag - true or
                  false. It is recommended to set this field to true so that
                  connection is automatically established after a network
                  disruption.
                </td>
              </tr>
              <tr>
                <td>RetryTimeout</td>
                <td>
                  Indicate how long the device should be placed off-scan when
                  the timeouts value is reached. During this period, no read
                  requests are sent to the device and all data associated with
                  the read requests are set to bad quality. When this period
                  expires, the driver places the device on-scan and allows for
                  another attempt at communications.
                </td>
              </tr>
              <tr>
                <td>NoOfRequestFailures</td>
                <td>
                  Specify how many successive cycles of request timeouts and
                  retries occur before the device is placed off-scan.
                </td>
              </tr>
              <tr>
                <td>DiscardRequest (drop down)</td>
                <td>
                  Select whether write requests should be attempted during the
                  off-scan period. Disable (false) to always send write requests
                  regardless of the demotion period.
                </td>
              </tr>
            </table>
          </p>
          <p>Table 4-4: Auto Demotion Tab</p>
        </div>

        <div
          id="%4.exceltemplate%%4.5.tag%"
          className="level-2-padding"
          ref={refs["%4.exceltemplate%%4.5.tag%"]}
          onMouseOver={() => props.onDocClick("%4.exceltemplate%%4.5.tag%")}
        >
          <p className="headers-document first-header">4.5. Tag</p>
          <p>
            A tag represents addresses in the hardware device with which the
            server communicates. The Tag tag groups the attribute columns for
            configuring Tags.
          </p>
          <p>
            <img
              src="images/userDocImages/exceltemplate6.png"
              className="image-margin"
            />
          </p>
          <p>Figure 4-6: Tag Tab</p>
          <p>
            <table>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "80%" }} />
              </colgroup>
              <tr>
                <th colSpan={2}>Tag Tab </th>
              </tr>
              <tr>
                <th>Column Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>Channel Id (drop down)</td>
                <td>
                  This column specifies the parent channel id from the
                  ModbusTCPChannel tab.
                </td>
              </tr>
              <tr>
                <td>Device Id (drop down)</td>
                <td>
                  This column specifies the linked device id from the Device
                  tab.
                </td>
              </tr>
              <tr>
                <td>Id</td>
                <td>This column specifies a unique id for a tag.</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>
                  This column specifies the name of the tag. It can be any
                  logically user-defined name.
                </td>
              </tr>
              <tr>
                <td>Data Type (drop down)</td>
                <td>
                  Specify the format of this tag's data as it is found in the
                  physical device. The data type setting is an important part of
                  how a communication driver reads and writes data to a device.
                  Please see the next table for the list of supported data
                  types. The data type can be selected from a drop-down field in
                  this column.
                </td>
              </tr>
              <tr>
                <td>Description</td>
                <td>This column specifies the description for the tag.</td>
              </tr>

              <tr>
                <td>Scan Rate</td>
                <td>This column specifies the update interval for the tag.</td>
              </tr>
              <tr>
                <td>Display Format</td>
                <td>
                  This column specifies the data formating information.
                  Applicable for float data type. Ex: For 0.5f. Here we specify
                  "5" digits of precision and "f" is used to represent floating
                  point number.
                </td>
              </tr>
              <tr>
                <td>Access Level (drop down)</td>
                <td>
                  This column specifies the access level for each tag. Can be
                  CurrentRead or CurrentReadorWrite.
                </td>
              </tr>
              <tr>
                <td>Function (drop down)</td>
                <td>
                  Function attribute specifies whether the desired parameter is
                  HoldingRegister, InputRegister, Coil, DiscreteInput,
                  Diagnostics, FileRecords or ReadWriteMultipleRegisters.
                </td>
              </tr>
              <tr>
                <td>Address</td>
                <td>Address column specifies the target tag's address</td>
              </tr>
              <tr>
                <td>Quantity</td>
                <td>
                  Quantity Attribute specifies No. of values to be retrieved
                  from the device based on the data type specified.
                </td>
              </tr>
              <tr>
                <td>WriteStartAddress</td>
                <td></td>
              </tr>
              <tr>
                <td>ReadQuantity</td>
                <td></td>
              </tr>
              <tr>
                <td>SubFunctionCode</td>
                <td></td>
              </tr>

              <tr>
                <td>Scaling Factor (drop down)</td>
                <td>
                  This property specifies if that raw data from the device to be
                  scaled to an appropriate range for the application. Possible
                  values are None, Linear, Square root. The next set of columns
                  is relevant only when the scaling factor value is assigned to
                  Linear or Square root as we have to calculate the scaling
                  factor for the device value. Scaling is disabled if Scaling
                  Factor is None and the scaling values will be ignored.
                </td>
              </tr>
              <tr>
                <td>RawLow</td>
                <td>
                  Specify the lower end of the range of data from the device.
                  The valid range depends on the raw tag data type
                </td>
              </tr>
              <tr>
                <td>RawHigh</td>
                <td>
                  Specify the upper end of the range of data from the device.
                  The Raw High value must be greater than the Raw Low value. The
                  valid range depends on the raw tag data type
                </td>
              </tr>
              <tr>
                <td>ScaledLow</td>
                <td>
                  Specify the lower end of the range of valid resulting scaled
                  data values. The valid range depends on the tag data type
                </td>
              </tr>
              <tr>
                <td>ScaledHigh</td>
                <td>
                  Specify the upper end of the range of valid resulting scaled
                  data values. The valid range depends on the tag data type
                </td>
              </tr>
              <tr>
                <td>Scaled Data Type (drop down)</td>
                <td>
                  Specify the data type for the tag being scaled as part of the
                  Scaling Factor function being applied. The data type can be
                  set to any valid OPC data type
                </td>
              </tr>
            </table>
          </p>
          <p>Table 4-5: Tag Tab</p>
          <p>Supported Data Types:</p>
          <p>
            <table>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "40%" }} />
                <col span={1} style={{ width: "40%" }} />
              </colgroup>
              <tr>
                <th>Sl. No </th>
                <th>Quantity </th>
                <th>Data Type </th>
              </tr>
              <tr>
                <td> 1</td>
                <td> 1</td>
                <td>Boolean </td>
              </tr>
              <tr>
                <td>2 </td>
                <td>2 </td>
                <td>Float </td>
              </tr>
              <tr>
                <td>3 </td>
                <td>2 </td>
                <td>Int32</td>
              </tr>
              <tr>
                <td>4</td>
                <td>4 </td>
                <td>Double</td>
              </tr>
              <tr>
                <td>5</td>
                <td>1 </td>
                <td>Int16</td>
              </tr>
              <tr>
                <td>6</td>
                <td>4 </td>
                <td>Int64</td>
              </tr>
              <tr>
                <td>7</td>
                <td> 1</td>
                <td>UInt16</td>
              </tr>
              <tr>
                <td>8</td>
                <td>2 </td>
                <td>UInt32</td>
              </tr>
              <tr>
                <td>9</td>
                <td>4 </td>
                <td>UInt64</td>
              </tr>
              <tr>
                <td>10</td>
                <td>2 </td>
                <td>String</td>
              </tr>
              <tr>
                <td>11</td>
                <td> 1 * Number of Size</td>
                <td>BooleanArray</td>
              </tr>
              <tr>
                <td>12</td>
                <td>2 * Number of Size </td>
                <td>IntArray</td>
              </tr>
              <tr>
                <td>13</td>
                <td>2 * Number of Size </td>
                <td>UIntArray</td>
              </tr>
              <tr>
                <td>14</td>
                <td>1 * Number of Size </td>
                <td>ShortArray</td>
              </tr>
              <tr>
                <td>15</td>
                <td>1 * Number of Size </td>
                <td>UShortArray</td>
              </tr>
              <tr>
                <td>16</td>
                <td>4 * Number of Size </td>
                <td>LongArray</td>
              </tr>
              <tr>
                <td>17</td>
                <td>4 * Number of Size </td>
                <td>ULongArray</td>
              </tr>
              <tr>
                <td>18</td>
                <td>2 * Number of Size </td>
                <td>FloatArray</td>
              </tr>
              <tr>
                <td>19</td>
                <td>4 * Number of Size </td>
                <td>DoubleArray</td>
              </tr>
            </table>
          </p>
          <p>Table 4-6: Supported Data Types</p>
        </div>
        <div
          id="%4.exceltemplate%%4.6.type%"
          className="level-2-padding"
          ref={refs["%4.exceltemplate%%4.6.type%"]}
          onMouseOver={() => props.onDocClick("%4.exceltemplate%%4.6.type%")}
        >
          <p className="headers-document first-header">4.6. Type</p>
          <p>
            The Type Tab defines the object type definition to be applied on
            devices. System maintains a master list of types which can then be
            referenced by devices.
          </p>
          <p>
            <img
              src="images/userDocImages/exceltemplate7.png"
              className="image-margin"
            />
          </p>
          <p>Figure 4-7: Type Tab</p>
          <p>
            <table>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "80%" }} />
              </colgroup>
              <tr>
                <th colSpan={2}>Type Tab </th>
              </tr>
              <tr>
                <th>Column Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>Id</td>
                <td>Unique Type Id string.</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>Type Name</td>
              </tr>
            </table>
          </p>
          <p>Table 4-7: Type Tab</p>
        </div>

        <div
          id="%4.exceltemplate%%4.7.variable%"
          className="level-2-padding"
          ref={refs["%4.exceltemplate%%4.7.variable%"]}
          onMouseOver={() =>
            props.onDocClick("%4.exceltemplate%%4.7.variable%")
          }
        >
          <p className="headers-document first-header">4.7. Variable </p>
          <p>
            Variable for a type has Name and DataType properties like properties
            available on a Tag. This tab lists the Variables and its attribute
            columns.
          </p>
          <p>
            <img
              src="images/userDocImages/exceltemplate8.png"
              className="image-margin"
            />
          </p>
          <p>Figure 4-8: Variables Tab</p>
          <p>
            <table>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "80%" }} />
              </colgroup>
              <tr>
                <th colSpan={2}>Variables Tab </th>
              </tr>
              <tr>
                <th>Column Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>Type Id (drop down)</td>
                <td>
                  This column specifies the type id for the variable. The Type
                  Id should be from the configured Types in Types tab.
                </td>
              </tr>
              <tr>
                <td>Id</td>
                <td>This column specifies the id of the variable.</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>This column specifies the name of the variable</td>
              </tr>
              <tr>
                <td>DataType (drop down)</td>
                <td>
                  Specify the data type for the variable. The data type can be
                  set to any valid OPC data type. The supported Data types is
                  listed in the Tags section.
                </td>
              </tr>
            </table>
          </p>
          <p>Table 4-8: Variables Tab</p>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Documentation);
